import React from 'react'

const TransportProgram = ({ color, transport: { details, dates, subtitle, route } }) => {
  return (
    <div>
      <p><strong><span style={{ color }}>{dates}</span> {subtitle}</strong></p>
      <p dangerouslySetInnerHTML={{ __html: details }} />
      <p dangerouslySetInnerHTML={{ __html: route }} />
    </div>
  )
}

export default TransportProgram
