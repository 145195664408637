import React from 'react'
import { Segment, Header } from 'semantic-ui-react'

import ImageSectionSlider from './ImageSectionSlider'
import { unescape, nl2br } from '../../utils/helpers'

const HotelDescription = ({ lightboxId, section, images, inline, aspectRatio = 1, thumbWidth = 300 }) => {
  if (!section && !images) {
    return ''
  }

  return (
    <Segment padded='very' vertical>
      {inline ? (
        <p>
          {section && <strong>{unescape(section.title)}: </strong>}
          {section && <span dangerouslySetInnerHTML={{ __html: nl2br(section.description) }} />}
        </p>
      ) : (
        <div>
          {section && <Header as='h2' color='red'>{unescape(section.title)}</Header>}
          {section && <p dangerouslySetInnerHTML={{ __html: nl2br(section.description) }} />}
        </div>
      )}

      {images && <ImageSectionSlider lightboxId={lightboxId} images={images} aspectRatio={aspectRatio} thumbWidth={thumbWidth} />}
    </Segment>
  )
}

export default HotelDescription
