import React from 'react'

const FlightProgram = ({ color, flight: { dates, route, info, seats, details } }) => {
  return (
    <div>
      {details && <p dangerouslySetInnerHTML={{ __html: details }} />}
      <p>
        <strong><span style={{ color }}>{dates}</span> {route}</strong><br />
        <span>{info}</span><br />
        <span>{seats}</span>
      </p>
    </div>
  )
}

export default FlightProgram
