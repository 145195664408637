/* global Image */

if (typeof window !== 'undefined') {
  const JsPDF = require('jspdf')

  const font400 = require('../assets/fonts/ronnia_400_base64')
  const font300 = require('../assets/fonts/ronnia_300_base64')
  const i18next = require('i18next')
  const helpers = require('./helpers')
  const imagesHelper = require('./images')
  const dossier = require('./dossier')

  const airlines = require('../assets/pdf-icons/airlines.png')
  const transports = require('../assets/pdf-icons/transports.png')
  const hotels = require('../assets/pdf-icons/hotels.png')
  const trips = require('../assets/pdf-icons/trips.png')
  const tours = require('../assets/pdf-icons/tours.png')
  const logoDe = require('../assets/tourasia-logo-de.png')
  const logoFr = require('../assets/tourasia-logo-fr.png')

  module.exports = async (data, { print, language }) => {
    const { agency: { customColor, customLogo, isAgency }, general, general: { dossierNumber, createDate, customerNumber }, employee: { name, phone, mail, gender }, prices, infoPages, sections } = data
    const customColorRgb = hexToRgb(customColor)

    const getRawImage = imagesHelper.getRawImage
    const t = i18next.t
    const br2nl = helpers.br2nl
    const unescape = helpers.unescape
    const ronnia300 = font300.ronnia300
    const ronnia400 = font400.ronnia400
    const fetchHotelDetails = dossier.fetchHotelDetails
    const fetchDestinationDetails = dossier.fetchDestinationDetails

    const black = '#000000'
    const white = '#ffffff'
    const lightgray = '#ebebeb'
    const graySecond = '#cccccc'
    const darkgray = '#4d4d4d'

    const sizeSmall = 10
    const sizeP = 12
    const sizeH1 = 20
    const sizeH2 = 18
    const sizeH3 = 14

    const iconSize = 50
    const imageWidth = 535
    const imageHeight = 360

    const doc = new JsPDF('portrait', 'pt')

    doc.addFileToVFS('ronnia-normal.ttf', ronnia300)
    doc.addFont('ronnia-normal.ttf', 'ronnia-normal', 'normal')
    doc.addFileToVFS('ronnia-bold.ttf', ronnia400)
    doc.addFont('ronnia-bold.ttf', 'ronnia-bold', 'normal')

    const pageWidth = doc.internal.pageSize.getWidth()
    const pageHeight = doc.internal.pageSize.getHeight()

    let text = ''
    let posY = 30
    let margin = 30
    let boxSize = pageWidth - margin * 2
    let center = pageWidth / 2

    await placeGeneral()
    placeEmployee()
    if (prices.visibility && prices.visibility.priceBox) {
      placePrices()
    }
    await placeSections()
    placeInfoPages()

    if (print) {
      doc.autoPrint()
    }

    doc.save(`${t('dossier.general.offer')} ${general.title} ${t('dossier.prices.from')} ${general.firstDate} ${t('dossier.prices.to')} ${general.lastDate}.pdf`)

    return true

    async function placeGeneral () {
      if (isAgency) {
        if (customLogo) await placeImage({ cloudinary: customLogo, width: 200 })
      } else {
        doc.addImage(language === 'de' ? logoDe : logoFr, 'PNG', margin, posY, 200, 53)
      }

      posY += 150
      placeText(`${t('dossier.general.number')}: N° ${dossierNumber} | ${t('dossier.general.dates')}: ${createDate} | ${t('dossier.general.client')}: ${customerNumber}`, { marginLeft: center, textAlign: 'center', fontSize: sizeSmall, color: graySecond })
      posY += 10
      placeLongText(`${t('dossier.general.offer')} «${general.title}» ${t('dossier.prices.from')} ${general.firstDate} ${t('dossier.prices.to')} ${general.lastDate}`, { marginLeft: center, textAlign: 'center', fontWeight: 'bold', fontSize: sizeH1 })
      placeLongText(`${t('dossier.general.offer-for')} ${general.travellers && general.travellers.map(t => t.name).join(', ')}`, { marginLeft: center, textAlign: 'center', fontWeight: 'bold', color: customColor, fontSize: sizeH3 })
      posY += 50

      placeLongText(t('dossier.general.intro'), { marginLeft: center, textAlign: 'center', marginBottom: 60 })
      posY += 40
    }

    function placeEmployee () {
      doc.setFillColor(customColorRgb.r, customColorRgb.g, customColorRgb.b)
      doc.rect(margin, posY, boxSize, 120, 'F')
      doc.setFont(`ronnia-bold`)
      doc.setFontSize(sizeH3)
      doc.setTextColor(white)
      text = doc.splitTextToSize(replaceI18n(t(`dossier.employee.ask.${gender}`)), 450)
      doc.text(text || '', center, posY += 30, 'center')
      doc.setFontSize(sizeP)
      doc.text(name || '', center, posY += 50, 'center')
      doc.setFont(`ronnia-normal`)
      doc.text(`${t('dossier.employee.phone')}: ${phone}, ${mail}`, center, posY += 15, 'center')

      newPage()
    }

    function placePrices () {
      const { items, summaryPrices, visibility } = prices

      // table title
      placeText(t('dossier.prices.price'), { fontWeight: 'bold', color: customColor, fontSize: sizeH2 })

      // table headings
      placeText(t('dossier.prices.desc'), { fontWeight: 'bold', newLine: false })

      if (visibility.serviceItems) {
        placeText(t('dossier.prices.traveller'), { fontWeight: 'bold', marginLeft: margin + 300, newLine: false })
        placeText(t('dossier.prices.total'), { fontWeight: 'bold', marginLeft: pageWidth - 35, textAlign: 'right' })
      } else {
        placeText(t('dossier.prices.traveller'), { fontWeight: 'bold', marginLeft: pageWidth - 35, textAlign: 'right' })
      }

      // table rows with list of services and optional prices
      items.forEach(({ beginDate, endDate, members, prices, title }) => {
        placeLine()

        placeText(`${beginDate} ${endDate ? ' - ' + endDate : ''}`, { fontWeight: 'bold', color: customColor, newLine: false })

        if (visibility.serviceItems) {
          placeText(`${members.join(', ')}`, { marginLeft: margin + 300, newLine: false })
          placeText(`${prices.pricePerUnit.toFixed(2)} / ${prices.totalPrice.toFixed(2)}`, { fontWeight: 'bold', marginLeft: pageWidth - 35, textAlign: 'right', newLine: false })
        } else {
          placeText(`${members.join(', ')}`, { marginLeft: pageWidth - 35, textAlign: 'right' })
        }

        posY = posY + Number(beginDate ? 16 : 0)

        placeLongText(title, { fontWeight: 'bold', maxWidth: 300 })

        visibility.serviceItems && prices.subprices.forEach(({ members, pricePerUnit, totalPrice, description }) => {
          placeText(`${members.join(', ')}`, { marginLeft: margin + 300, newLine: false })
          placeText(`${pricePerUnit.toFixed(2)} / ${totalPrice.toFixed(2)}`, { marginLeft: pageWidth - 35, textAlign: 'right', newLine: false })
          placeLongText(description, { maxWidth: 300 })
        })
      })

      posY += 40
      if (posY >= pageHeight - 80) newPage()

      // summary prices per person
      if (visibility.perPerson) {
        summaryPrices.personPrices.forEach(({ id, name, price }) => {
          doc.setFillColor(customColorRgb.r, customColorRgb.g, customColorRgb.b)
          doc.rect(margin, posY, boxSize, 50, 'F')
          posY += 30
          placeText(`${name} (${id})`, { fontWeight: 'bold', fontSize: sizeH3, color: white, marginLeft: margin + 20, newLine: false })
          placeText(`CHF ${price.toFixed(2)}`, { fontWeight: 'bold', fontSize: sizeH3, color: white, marginLeft: pageWidth - 55, textAlign: 'right', newLine: false })
          posY += 20
        })
      }

      // summary prices total
      if (visibility.total) {
        doc.setFillColor(153, 153, 153)
        doc.rect(margin, posY, boxSize, 50, 'F')
        posY += 30
        placeText(t('dossier.prices.sum'), { fontWeight: 'bold', fontSize: sizeH3, color: white, marginLeft: margin + 20, newLine: false })
        placeText(`CHF ${summaryPrices.totalPrice.toFixed(2)}`, { fontWeight: 'bold', fontSize: sizeH3, color: white, marginLeft: pageWidth - 55, textAlign: 'right', newLine: false })
      }

      newPage()
    }

    async function placeSections () {
      for (let item of sections) {
        switch (item.type) {
          case 'airlines':
            placeSectionHeader({ icon: airlines, title: item.title, travellers: item.travellers })
            placeSectionAirlines(item)
            break

          case 'transports':
            placeSectionHeader({ icon: transports, title: item.title, travellers: item.travellers })
            placeSectionTransports(item)
            break

          case 'hotels':
            placeSectionHeader({ icon: hotels, title: item.title, travellers: item.travellers, withImage: !!item.details.images })
            await placeSectionHotels(item)
            break

          case 'trips':
            placeSectionHeader({ icon: trips, title: item.title, travellers: item.travellers, withImage: !!item.description.images })
            await placeSectionTrips(item)
            break

          case 'tours':
            placeSectionHeader({ icon: tours, title: item.title, travellers: item.travellers })
            await placeSectionTours(item)
            break

          default:
            break
        }

        posY += 30
      }

      if (infoPages.length) {
        newPage()
      }

      return true
    }

    function placeSectionHeader ({ icon, title, travellers, withImage = false }) {
      const sectionMargin = 60
      const iconRadius = iconSize / 2

      if (withImage && posY + iconSize + imageHeight + margin + sectionMargin > pageHeight) {
        newPage()
      } else if (posY + iconSize + margin + sectionMargin > pageHeight) {
        newPage()
      }

      doc.setFillColor(customColor)
      doc.circle(margin + iconRadius, posY + iconRadius, iconRadius, 'F')
      doc.addImage(icon, 'PNG', margin + 12, posY + 12, 25, 25)
      posY += 15

      placeText(title, { color: customColor, fontSize: sizeH3, fontWeight: 'bold', marginLeft: margin + iconSize + 10, marginBottom: 2 })
      placeLongText(`${t('dossier.travellers')}: ${travellers && travellers.join(', ')}`, { color: darkgray, marginLeft: margin + iconSize + 10, maxWidth: 400 })

      posY += 10
      placeLine({ marginBottom: 25 })
    }

    function placeSectionAirlines ({ dates, route, info, seats, details }) {
      if (details) {
        placeLongText(br2nl(details))
        posY += sizeP
      }

      placeColoredSubtitle(dates, route)
      posY += sizeP

      placeText(info)
      placeText(seats)
    }

    function placeSectionTransports ({ dates, subtitle, route, details }) {
      placeColoredSubtitle(dates, subtitle)
      posY += sizeP
      placeLongText(details, { marginBottom: sizeP })
      placeText(route)
    }

    async function placeSectionHotels ({ dates, subtitle, details, overview }) {
      if (details && details.images) {
        await placeImage({ cloudinary: details.images.headers[0].cloudinary })
      }

      placeColoredSubtitle(dates, subtitle)
      posY += sizeP

      placeText(overview.room, { marginBottom: sizeP })
      placeText(overview.included, { marginBottom: sizeP })

      if (posY >= pageHeight - 120) newPage()

      if (details && details.description) {
        placeHotelDescription(details.description)
      }

      return true
    }

    function placeHotelDescription (desc) {
      if (desc.intro) {
        placeLongText(desc.intro.description, { marginBottom: sizeP })
      }

      if (desc.facility) {
        placeText(desc.facility.title, { fontWeight: 'bold' })
        placeLongText(desc.facility.description, { marginBottom: sizeP })
      }

      if (desc.catering) {
        placeText(desc.catering.title, { fontWeight: 'bold' })
        placeLongText(desc.catering.description, { marginBottom: sizeP })
      }

      if (desc.leisure) {
        placeText(desc.leisure.title, { fontWeight: 'bold' })
        placeLongText(desc.leisure.description, { marginBottom: sizeP })
      }

      if (desc.opinion) {
        placeText(desc.opinion.title, { fontWeight: 'bold' })
        placeLongText(desc.opinion.description, { marginBottom: sizeP })
      }

      if (desc.location) {
        placeText(desc.location.title, { fontWeight: 'bold' })
        placeLongText(desc.location.description, { marginBottom: sizeP })
      }
    }

    async function placeSectionTrips ({ dates, subtitle, description, arrival, departure, details }) {
      if (description && description.images) {
        await placeImage({ cloudinary: description.images[0].cloudinary })
      }

      placeColoredSubtitle(dates, subtitle)
      posY += 10

      placeColoredSubtitle(arrival.time, arrival.title)
      placeColoredSubtitle(departure.time, departure.title)
      posY += 10

      placeLongText(details)

      return true
    }

    async function placeSectionTours ({ dates, subtitle, overview, items }) {
      placeColoredSubtitle(dates, subtitle)
      posY += sizeP

      placeText(overview.title, { fontWeight: 'bold' })
      placeLongText(overview.details)

      for (let item of items) {
        if (item.type === 'text') {
          placeLine({ color: customColor, marginBottom: 40 })

          placeText(item.date, { fontSize: sizeP })
          placeLongText(`${item.title}`, { color: customColor, fontSize: sizeH3, maxWidth: boxSize - margin * 2, fontWeight: 'bold' })

          placeText(`${t('dossier.program.program')}:`, { color: customColor, fontWeight: 'bold' })
          placeLongText(item.details)
        } else if (item.type === 'region') {
          placeColoredSubtitle(`${t('dossier.program.region')}:`, item.title)
          placeLongText(item.details)

          if (item.code) {
            const destinationDetails = await fetchDestinationDetails(item.code)

            if (destinationDetails.images) {
              await placeImage({ cloudinary: destinationDetails.images.standard[0].cloudinary })
            }
          }
        }

        if (item.hotels && !!item.hotels.length) {
          posY += 20
          for (let hotel of item.hotels) {
            if (hotel.code) {
              const hotelDetails = await fetchHotelDetails(hotel.code)

              if (hotelDetails.images && posY + imageHeight + 30 > pageHeight - 30) {
                newPage()
              }

              placeText(`${t('dossier.program.accommodation')}:`, { color: customColor, fontWeight: 'bold' })
              placeText(hotel.title, { fontWeight: 'bold' })

              if (hotelDetails.images) {
                await placeImage({ cloudinary: hotelDetails.images.headers[0].cloudinary })
              }

              if (hotelDetails.description) {
                placeHotelDescription(hotelDetails.description)
              }
            } else {
              placeText(`${t('dossier.program.accommodation')}:`, { color: customColor, fontWeight: 'bold' })
              placeText(hotel.title, { fontWeight: 'bold' })
            }
          }
        }

        posY += 20
      }

      return true
    }

    function placeInfoPages () {
      if (!infoPages.length) {
        return ''
      }

      placeText(t('dossier.prices.more-info'), { color: customColor, fontSize: sizeH2, fontWeight: 'bold', marginBottom: 30 })

      infoPages.forEach(({ title, details }) => {
        placeText(title, { fontWeight: 'bold', marginBottom: 20 })
        placeLongText(details)

        placeLine()
      })
    }

    function newPage () {
      doc.addPage()
      posY = margin
    }

    async function placeImage ({ cloudinary, width }) {
      if (posY + imageHeight > pageHeight) newPage()

      const imgSrc = getRawImage({ cloudinary, width: width || imageWidth })
      const { imgData, height } = await getDataUri(imgSrc)

      doc.addImage(imgData, 'PNG', margin, posY, width || imageWidth, height || imageHeight)
      posY += height + 20

      if (posY + 120 > pageHeight) newPage()

      return true
    }

    function placeText (shyText, options = {}) {
      if (!shyText) return false

      const text = unescape(shyText.replace(/[\u00AD\u002D\u2011]+/g, ''))
      const { fontFamily = 'ronnia', fontWeight = 'normal', fontSize = sizeP, color = black, marginBottom = 0, marginLeft = margin, newLine = true, textAlign = 'left' } = options
      if (posY >= pageHeight - 50) newPage()

      doc.setFont(`${fontFamily}-${fontWeight}`)
      doc.setTextColor(color)
      doc.setFontSize(fontSize)
      doc.text(text, marginLeft, posY, textAlign)

      if (newLine) {
        posY += fontSize + Number(marginBottom || (fontSize / 3))
      }

      if (posY >= pageHeight - 50) newPage()
    }

    function placeLongText (shyText, options = {}) {
      if (!shyText) return false

      if (posY >= pageHeight - 50) newPage()

      const text = unescape(shyText.replace(/[\u00AD\u002D\u2011]+/g, ''))
      const { color = black, fontSize = sizeP, fontFamily = 'ronnia', fontWeight = 'normal', textAlign = 'left', marginLeft = margin, maxWidth = boxSize, marginBottom = 0 } = options

      let splittedText = doc.splitTextToSize(removeHtml(br2nl(text)), maxWidth, options)

      splittedText.forEach(text => {
        placeText(text, { fontSize, fontFamily, fontWeight, color, marginLeft, textAlign })
      })

      posY += marginBottom

      if (posY >= pageHeight - 50) newPage()
    }

    function placeColoredSubtitle (text1, text2) {
      if (posY >= pageHeight - 30) newPage()

      if (doc.getTextDimensions(text1 + text2).w > boxSize) {
        placeText(text1, { color: customColor, fontWeight: 'bold' })
        placeText(text2, { fontWeight: 'bold' })
      } else {
        placeText(text1, { color: customColor, fontWeight: 'bold', newLine: false })
        placeText(text2, { fontWeight: 'bold', marginLeft: margin + doc.getTextDimensions(text1).w + 12 })
      }

      if (posY >= pageHeight - 30) newPage()
    }

    function placeLine (options = {}) {
      const { color = lightgray, marginBottom = 20 } = options

      doc.setDrawColor(color)
      doc.line(margin, posY, pageWidth - margin, posY)
      posY += marginBottom
    }
  }
} else {
  module.exports = async (data, { print }) => {
    return false
  }
}

function removeHtml (str) {
  return String(str).replace(/(<([^>]+)>)/ig, '')
}

function replaceI18n (str) {
  return String(str).replace('<1></1>', '\n')
}

function getDataUri (url) {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.crossOrigin = 'Anonymous'

    image.onload = async function () {
      var canvas = document.createElement('canvas')
      canvas.width = this.naturalWidth // or 'width' if you want a special/scaled size
      canvas.height = this.naturalHeight // or 'height' if you want a special/scaled size

      canvas.getContext('2d').drawImage(this, 0, 0)

      // ... or get as Data URI
      resolve({ imgData: canvas.toDataURL('image/png'), width: this.naturalWidth, height: this.naturalHeight })
    }

    image.onerror = reject
    image.src = url
  })
}

function hexToRgb (hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null
}
