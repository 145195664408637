import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Icon, Button } from 'semantic-ui-react'

import ImageHeaderSlider from '../Items/ImageHeaderSlider'
import HotelDescriptions from './HotelDescriptions'

class HotelProgram extends Component {
  state = { readMore: false }

  toggleMore = () => {
    this.setState(prev => ({ readMore: !prev.readMore }))
  }

  render () {
    const { readMore } = this.state
    const { color, hotel: { id, dates, subtitle, overview, details }, t } = this.props

    return (
      <div>
        {/* FETCHED IMAGES */}
        {details && details.images && <ImageHeaderSlider images={details.images.headers} aspectRatio={1.5} noTransform />}

        {/* SUBTITLE */}
        <p><span style={{ color }}>{dates}</span> <strong>{subtitle}</strong></p>
        {/* OVERVIEW INFO */}
        <p>{overview.room}<br />{overview.included}</p>

        {/* READ MORE */}
        {details && details.description &&
          <p style={{ textAlign: 'center' }}>
            <Button size='large' style={{ backgroundColor: color }} icon labelPosition='right' inverted onClick={this.toggleMore} className='dossier-button'>
              {`${readMore ? t('item-list.less') : t('item-list.more')}`}
              <Icon className={`${readMore ? 'ta-minus' : 'ta-plus'}`} />
            </Button>
          </p>
        }

        {readMore && details && details.description && <HotelDescriptions lightboxId={`hotel-${id}`} {...details} />}
      </div>
    )
  }
}

export default withTranslation()(HotelProgram)
