import React from 'react'

import ImageHeaderSlider from '../Items/ImageHeaderSlider'

const TripProgram = ({ color, transport: { details, dates, subtitle, arrival, departure, description } }) => {
  return (
    <div>
      {description.images && <ImageHeaderSlider images={description.images} aspectRatio={1.5} noTransform />}

      <p><strong><span style={{ color }}>{dates}</span> {subtitle}</strong></p>
      <p>
        <strong>
          <span style={{ color }}>{arrival.time}</span> {arrival.title} - <span style={{ color }}>{departure.time}</span> {departure.title}
        </strong>
      </p>

      <p dangerouslySetInnerHTML={{ __html: details }} />
    </div>
  )
}

export default TripProgram
