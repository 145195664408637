import React from 'react'

const MiscProgram = ({ color, misc: { details, dates, subtitle } }) => {
  return (
    <div>
      <p><strong><span style={{ color }}>{dates}</span> {subtitle}</strong></p>
      <p dangerouslySetInnerHTML={{ __html: details }} />
    </div>
  )
}

export default MiscProgram
